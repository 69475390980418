import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { Cardcolumn,Grid,ItemHead,Item,SectionDescription } from './CardFlexboxCVElements';
import { Trans, withTranslation } from 'react-i18next';
import LargeFeature2Pic from '../../assets/images/accueil/tom2.jpg';

class CardFlexboxCV extends Component {

    constructor (props) {
        super(props)
        this.state = {
            toggler: false,
            slide: 1
        }
    }

    setToggler = (slide) => {
        this.setState({ toggler: !this.state.toggler, slide: slide })
    }

    render() {
  
      return (
        <Cardcolumn>
          <SectionDescription >
            <h2>{this.props.data.section}</h2>
            <p>{this.props.data.section_additional_description}</p>
          </SectionDescription>
          <Grid>
            <ItemHead>{this.props.data.title1}</ItemHead>
            <ItemHead>{this.props.data.title2}</ItemHead>
            {this.props.data.content.map((content, index) => {
              if (content.link) 
                return (
                  <React.Fragment key={index}>
                    <Item>{content.years}</Item>
                    <Item><Trans i18nKey={content.description} ><a rel="noreferrer" target="_blank" href={content.link}>{content.description}</a></Trans></Item>
                  </React.Fragment>
                );
              else
                return (
                  <React.Fragment key={index}>
                    <Item>{content.years}</Item>
                    <Item>{content.description}</Item>
                  </React.Fragment>
                );
            })}
            </Grid>
        </Cardcolumn>
      );
    }
}

// We are passing a default theme for Buttons that arent wrapped in the ThemeProvider
CardFlexboxCV.defaultProps = {
  title: 'This is the default title',
  figures: [LargeFeature2Pic],
  captions: [ <> 'This is the default picture my face !!'</> ],
  text: 'This is the default description. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
  data: {
          section: 'Life evolution - main events',
          section_additional_description: 'Events with no importance like Solar birthday is avoided.', 
          title1: 'Year',
          title2: 'Event description',
          content: [ { years: '2099-2100',
          description: 'Body defreezing and conscience transfer to robot host'},
          { years: '2100-2200',
            description: 'Travel to Centuri21'},
          { years: '2200-',
            description: 'This is the default description. \n\n\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'} ]
        }
}

export default withTranslation()(CardFlexboxCV)

