import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {Main} from './ScrollToTopAutoElements';

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
//			window.onscroll = function() {};
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return (
	  <Main>
	    <Fragment>{children}</Fragment>;
		</Main>
	)
}

export default withRouter(ScrollToTop);
