import React, { Component } from 'react';
import Logo from "../../assets/images/uib.png"
import LanguageSelect from "./languageSelect";

class content extends Component {
    
    render() {
       return (

           <div className="Banner-logo">
              <img src={Logo} alt="logo" className="logo" />
              <h1 className="Banner-logo1">
                  {this.props.tag || 'Home'}
              </h1>
              <div className="Banner-logo2">
              <div className="language-select">
                 <LanguageSelect />
              </div>
              </div>
           </div>
       )
    }
}

export default content;

