import styled from "styled-components";
import { NavLink } from 'react-router-dom';

export const CnavLink = styled(NavLink)`
  text-decoration: underline;
  color: #000;
  padding-left: 10px;

  position: relative;
  overflow: hidden;

//  background: linear-gradient(to right, midnightblue, midnightblue 50%, royalblue 50%);
  background: linear-gradient(to right, royalblue, royalblue 50%, #000 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;

  &:visited,link {
    color: #000;
    text-decoration: none;
    position: relative;
  };
  &:hover {
    background-position: 0 100%;
  }
`

export const ListUl = styled.ul`
  padding: 0px 30px 30px 30px;
  margin: 0px;
  list-style-type: disc;

  li {
     margin-left: 20px;
     padding-top: 10px;
     @media all and (max-width: 800px) {
        margin-left: 15px;
     }
  }
`

export const Caption = styled.div`
  font-size: var(--CaptionFontSize);

	a:link, a:visited {
	  color:red;
	}
  a:hover {
	  color: #ff8a80;
	}
	`;

export const Cardcolumn = styled.div`
  width: 80%;
  margin: 20px auto 0 auto;
  max-width: 800px;
  -webkit-box-shadow: rgba(0,0,0,0.8) 0px 0 10px;
  -moz-box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;

  @media all and (max-width: 800px) {
    width: 100%;
  }
`;

export const Cardflex = styled.figure`
  margin: 0;
  display: flex;
  background-color: #fff;
  margin-bottom: 2rem;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;

  h3 {
    font-size: 1.5rem;
    margin: 15px;
    font-weight: 400;
  }
  h4 {
    font-size: 1.2rem;
    margin: 20px;
    font-weight: 350;
		font-style: italic;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 400;
    white-space: pre-wrap;
    margin: 15px;

		@media all and (max-width: 600px) {
		  clear: both;
		}
    @media all and (max-width: 800px) {
      flex-direction: column;
      border: 0;
      margin: 0;
    }
  }
`;

export const CardflexImg = styled.img`
  display: block;
  min-height:200px;
  max-height: 400px;
  max-width: 400px;
  float: right;
  margin: 10px;

	&:hover{
	  cursor: pointer;
	}

	@media all and (max-width: 600px) {
    width: 100%;
    margin: 0px auto 15px auto;
    height:auto;
    float:none;
	}
`;
