import React, {Suspense} from 'react';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import CardFlexboxRows from '../components/CardFlexboxRows';
import LargeFeature from '../components/LargeFeature';
import Wasatch_fault_Utah from '../assets/images/Wasatch_fault_Utah_GoogleEarth.jpg';

import './index.css';

// Define what props.theme will look like
const Theme = {
  bg_image: Wasatch_fault_Utah,
  title: 'Eastern rift flank (Basins and Ranges), Wasatch fault (Utah)',
  text: ''
}

const scrollDown = (ref) => {
  window.scrollTo({
      top: ref.current.offsetTop,
          behavior: 'smooth',
            });
};

function Page () {

  const { t } = useTranslation();

  const uib = useRef(null);
	const ups = useRef(null);
	const ump = useRef(null);

  return (
    <>
{/*			{t('courses').map((course, index) => {
           return (
					   <>
              <CardFlexboxRows data={t('courses.geov251')} />
							<CardFlexboxRows data={course} />
						</>
							)
      })}   */}

       <div className="section section1">
           <ul>
					     <li className="nav-link-scroll" onClick={() => scrollDown(uib)}>
                 {t('uib')}
               </li>

					     <li className="nav-link-scroll" onClick={() => scrollDown(ups)}>
                 {t('ups')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(ump)}>
							   {t('ump')}
							 </li>
           </ul>
      </div>

      <div className="section subtitles" ref={uib}>
        <p>{t('uib')}</p>
      </div>

      <CardFlexboxRows data={t('courses.geov251')} />
			<CardFlexboxRows data={t('courses.geov219')} />
			<CardFlexboxRows data={t('courses.geov113')} />
			<CardFlexboxRows data={t('courses.geov254')} />

      <div className="section subtitles" ref={ups}>
        <p>{t('ups')}</p>
      </div>

      <CardFlexboxRows data={t('courses.l1STU')} />
      <CardFlexboxRows data={t('courses.l2geo')} />
      <CardFlexboxRows data={t('courses.l3tes6')} />
      <CardFlexboxRows data={t('courses.l3upsi')} />
      <CardFlexboxRows data={t('courses.M1geodynamic')} />
      <CardFlexboxRows data={t('courses.M2capesSVT')} />
      <CardFlexboxRows data={t('courses.M2pgrn')} />
      <CardFlexboxRows data={t('courses.M2rstps')} />
      
      <div className="section subtitles" ref={ump}>
        <p>{t('ump')}</p>
      </div>

      <CardFlexboxRows data={t('courses.st101')} />
      <CardFlexboxRows data={t('courses.st201')} />
      <CardFlexboxRows data={t('courses.st305')} />
      <CardFlexboxRows data={t('courses.m2capesagreg')} />
      

      <LargeFeature theme={Theme} />
    </>
  );
}


const Loader = () => (
  <div className="Home">
    <img src={logo} className="Home-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function teaching() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}

