import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
/*
  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;
    font-family: 'Kanit', sans-serif;
    
  }
*/
  html {
    --color-back-background: #ffffff;
    --color-background: #ffffff;
    --color-background-grid: #e3efd9;
    --color-background-grid-elts: #a7cd97;
    --color-font-banner: #558234;
    --dimension-width: 960px;
    --dimension-width-nounits: 960;
    --CaptionFontSize: 16px; //1.5vw;
  }


  @import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');
  * {
     font-family: 'Sora', sans-serif;
     box-sizing: border-box;
     margin: 0;
     padding: 0;
  }
  html, body {
	  overflow-y: scroll !important;
		-webkit-overflow-scrolling: touch;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media all and (max-width: 800px) {
		  overflow-y: scroll !important;
		  margin: 0;
		}
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

`;

