import React, {Suspense} from 'react';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import CardFlexboxCV from '../components/CardFlexboxCV';
import CardFlexboxList from '../components/CardFlexboxList';
import LargeFeature from '../components/LargeFeature';
import Wasatch_fault_Utah from '../assets/images/Wasatch_fault_Utah_GoogleEarth.jpg';

// Define what props.theme will look like
const Theme = {
  bg_image: Wasatch_fault_Utah,
  title: 'Eastern rift flank (Basins and Ranges), Wasatch fault (Utah)',
  text: ''
}

function Page () {

  const { t } = useTranslation();

  return (
    <>
      <CardFlexboxCV data={t('cv.personal')} />
      <CardFlexboxCV data={t('cv.education')} />
      <CardFlexboxCV data={t('cv.positions')} />
      <CardFlexboxCV data={t('cv.projectmanag')} />
      <CardFlexboxCV data={t('cv.supervision')} />
      <CardFlexboxCV data={t('cv.otherprofexp')} />
      <CardFlexboxList data={t('cv.collaborators')} />
      <CardFlexboxList data={t('cv.trackrecord')} />
      <CardFlexboxCV data={t('cv.invitedtalks')} />
      <CardFlexboxCV data={t('cv.honors')} />

      <LargeFeature theme={Theme} />
    </>
  );
}


const Loader = () => (
  <div className="Home">
    <img src={logo} className="Home-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function curriculumvitae() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}

