import styled from "styled-components";

export const Main = styled.div`
    position: relative;
    background: var(--color-background);
/*    max-width: var(--dimension-width);  */
    height: 100%;
		overflow-y: scroll !important;
    overflow: hidden;
    margin: 0 auto;
    min-height: 80vh;
    display: block;
/*    align-items:center;
    justify-content: center;
    text-align: center; */
`;

