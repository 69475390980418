import styled from 'styled-components';
import LargeFeaturePic from '../../assets/images/Wasatch_fault_Utah_GoogleEarth.jpg';

export const LargeFeatureContainer = styled.div`
  background: /*linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.1)),*/
    url(${props => props.theme.bg_image});
  height: 100vh;
  max-height: 800px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 0 1rem;
  position:relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  white-space: pre-line;

  h1 {
    position:absolute;
    top: 0px;
    left: 0%;
    font-size: clamp(1rem, 2vw, 2rem);
    box-shadow: 3px 5px #fff;
  }

  p {
    position: absolute;
  //  margin-top: 1rem;
    top: 4rem;
    left: 5%;
    margin-bottom: auto;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    white-space: pre-line;
  }
`;

// We are passing a default theme for Buttons that arent wrapped in the ThemeProvider
LargeFeatureContainer.defaultProps = {
  theme: {
    bg_image: LargeFeaturePic
  }
}


export const LargeFeatureButton = styled.button`
  font-size: 1.4rem;
  padding: 0.6rem 3rem;
  border: none;
  background: #ffc500;
  color: #000;
  transition: 0.2s ease-out;

  &:hover {
    color: #fff;
    background: #e31837;
    transition: 0.2s ease-out;
    cursor: pointer;
  }
`;

