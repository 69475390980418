import React from 'react';
/*import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';*/
/*import {
  FooterContainer,
  FooterWrap,
  FooterCopyright,
  FooterCopyrightText,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink 
} from './FooterElements'; */
import {  FooterContainer, FooterWrap, FooterCopyright, FooterCopyrightText } from './FooterElements';
import { useTranslation } from 'react-i18next';

function Footer(props) {

  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FooterWrap>
        {/*
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/'>Pizza</SocialLogo>
            <SocialIcons>
              <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='/' target='_blank' aria-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href='/' target='_blank' aria-label='Youtube'>
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink
                href='//www.twitter.com/briandesignz'
                target='_blank'
                aria-label='Twitter'
                rel='noopener noreferrer'
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href='/' target='_blank' aria-label='Linkedin'>
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
        */}
        <FooterCopyright>
            <FooterCopyrightText> {t('copyright')} </FooterCopyrightText>
        </FooterCopyright>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;

