import React, { Component } from 'react';
import FsLightbox from 'fslightbox-react';
import { CnavLink,Cardcolumn,Cardflex,CardflexImg,Caption,ListUl } from './CardFlexboxElements.js'; 
import { withTranslation } from 'react-i18next';
import ResearchJSON from '../../content/research.json';
import { papers } from '../../content/Publications';

//require('dotenv').config();

class CardFlexbox extends Component {

    constructor (props) {
        super(props)
        this.state = {
            toggler: false,
            slide: 1
        }
    }

    setToggler = (slide) => {
        this.setState({ toggler: !this.state.toggler, slide: slide })
    }

    render() {
  
      const { t } = this.props;
 
      let allfigures  = [];
			let allcaptions = [];
			let allcaptionslinks = [];
			let allcaptionslinkstext = [];
			let allrefbooks = [];
			let allrefweblinks = [];
			let allselectedpublis = [];
			let allcaptionstitle = [];
			let text = "";
			let title = "";
			let subtitle = "";
      this.props.json.forEach((content,index) => {
			  content.assets.forEach((read,index) => {
          //allfigures[index] = "http://thomass-mbp-2.home:3000"+read;
					if ( read.substring(0, 1) === '/' ) { allfigures[index] = process.env.PUBLIC_URL+read; } else { allfigures[index] = read; }
				});
        content.assetscaps.forEach((read,index) => {
          allcaptions[index] = read;
				});
        content.assetscaptitles.forEach((read,index) => {
          allcaptionstitle[index] = read;
				});
        content.assetslinks.forEach((read,index) => {
          allcaptionslinks[index] = read;
				});
				if (content.hasOwnProperty('assetslinkstext')){
          content.assetslinkstext.forEach((read,index) => {
            allcaptionslinkstext[index] = read;
		  		});
				} 
				content.refbooks.forEach((read,index) => {
          allrefbooks[index] = read;
				});
        content.refweblinks.forEach((read,index) => {
          allrefweblinks[index] = read;
				});
        content.selectedpublis.forEach((read,index) => {
          allselectedpublis[index] = read;
				});

				text = content.text;
				title = content.title;
				subtitle = content.subtitle;
			});
			if (allcaptionslinkstext.length===0) {
			   allcaptionslinks.forEach((content,index) => {
			     allcaptionslinkstext[index]=content;
			   })
			}
			let nbook           = allrefbooks.length;
			let nlink           = allrefweblinks.length;
			let nselectedpublis = allselectedpublis.length;
			let ilink = -1;
			if (text === "") {
        text = this.props.text;
      }
			if (title === "") {
			  title = this.props.title;
			}
			if (subtitle === "") {
			  subtitle = this.props.subtitle;
			}

      return (
      <>
          <Cardcolumn>
              <FsLightbox
                  toggler={ this.state.toggler }
		              slide={this.state.slide}
                  sources={ allfigures }
		              captions={ 
									    allcaptions.map((content,index) => {
											  var count = (content.match(/<LINK>/g) || []).length;
											  return <Caption>
												         <h2> {allcaptionstitle[index]} </h2>
												  { count > 0 ? ( content.split('<LINK>').map( (item,id) => {
													      if ( id < count ) {ilink = ilink + 1;} 
													      return id < count ?
																   allcaptionslinks[ilink].substring(0, 4) === 'http' ?
																	   <React.Fragment key={id}> {item} (<a target="_blank" rel="noreferrer" href={allcaptionslinks[ilink]}>{allcaptionslinkstext[ilink]}</a>)</React.Fragment>
																	 :
																	   <CnavLink key={id} to={{ pathname: allcaptionslinks[ilink] }} >{allcaptionslinkstext[ilink]}</CnavLink> 
															  :
																   <React.Fragment key={id}> {item} </React.Fragment>
														} ) ) : ( content ) }
												</Caption>
											})
									}
		              disableThumbs={1>0}
		              slideshowTime={2000}
              />
              <Cardflex>
                <div>
                  <h3>{t(title)}</h3>
									<h4>{t(subtitle)}</h4>
                  <CardflexImg onClick={() => this.setToggler(1)} src={allfigures[0]}></CardflexImg>
                  <p>{t(text)}</p>

                  {nselectedpublis>0? (
									<ListUl>
									Selected publications:
									{allselectedpublis.map((id,ipub) => {
									return (
									papers.map((publication, index) => {
	                     return (

											   publication.id === id? (
											   <li key={index}>
											     {publication.authors}. {publication.title}. {publication.journal}. <a target="_blank" rel="noreferrer" href={publication.url}>{publication.url}</a>. <b>{publication.year}</b>
												 </li>
												 ):(null)

											 )
									})
									)
									})}
									</ListUl>
									):(null)}

                  {nbook>0? (
									<ListUl>
									Suggested books:
								  {allrefbooks.map((content,index) => {
                     return ( 
										   <li key={index}>
                          {content.type !== "book" ? (
													         content.authors.map((author,iauth) => {return(
                 
                                            <React.Fragment key={iauth}>
                                            {content.authors.length===1||content.authors.length===iauth+1 ? (
                                                <span>{author}</span>
                                              ) : (
                                   <>
                                              {content.authors.length===iauth+2 ? (
                                                <span>{author} and </span>
                                              ) : (
                                                <span>{author}, </span>
                                              ) }
                                   </>
                                              )}
                                            </React.Fragment>
                                   );})
													) : (
													         content.editors.map((author,iauth) => {return(
                 
                                            <React.Fragment key={iauth}>
                                            {content.editors.length===1||content.editors.length===iauth+1 ? (
                                                <span>{author}</span>
                                              ) : (
                                   <>
                                              {content.editors.length===iauth+2 ? (
                                                <span>{author} and </span>
                                              ) : (
                                                <span>{author}, </span>
                                              ) }
                                   </>
                                              )}
                                            </React.Fragment>
                                   );})
													) }.
											    <span> {content.title}.</span>
                          <span> (<span style={{fontWeight: 'bold'}} ><a target="_blank" rel="noreferrer" href={content.url}>{content.year}</a></span>)</span>
                       </li>
										 );
									})
									}
									</ListUl>
									):(null)}

                  {nlink>0? (
									<ListUl>
									Suggested web pages:
								  {allrefweblinks.map((content,index) => {
                     return ( 
										   <li key={index}>
											   <span><a target="_blank" rel="noreferrer" href={content.url}>{content.title}</a></span>
											 </li>
									   );
								  })
									}
									</ListUl>
									):(null)}

                </div>
              </Cardflex>
          </Cardcolumn>
	  </>
      );
    }
}
CardFlexbox.defaultProps = {
  json: ResearchJSON,
  figures: [],
	captions: [],
	title: "Default title",
	subtitle: "",
	text: "This is the default text. Nothing really important.",
	refbooks: "Book 1: The Flat Earth"
}
export default withTranslation()(CardFlexbox)

