import React, {Suspense} from 'react';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import CardFlexboxRows from '../components/CardFlexboxRows';
import LargeFeature from '../components/LargeFeature';
import Wasatch_fault_Utah from '../assets/images/Wasatch_fault_Utah_GoogleEarth.jpg';

import './index.css';

// Define what props.theme will look like
const Theme = {
  bg_image: Wasatch_fault_Utah,
  title: 'Eastern rift flank (Basins and Ranges), Wasatch fault (Utah)',
  text: ''
}

const scrollDown = (ref) => {
  window.scrollTo({
      top: ref.current.offsetTop,
          behavior: 'smooth',
            });
};

function Page () {

  const { t } = useTranslation();

  const geodyn = useRef(null);
	const gis    = useRef(null);
	const dev    = useRef(null);
  const seismo = useRef(null);

  return (
    <>
{/*			{t('courses').map((course, index) => {
           return (
					   <>
              <CardFlexboxRows data={t('courses.geov251')} />
							<CardFlexboxRows data={course} />
						</>
							)
      })}   */}

       <div className="section section1">
           <ul>
					     <li className="nav-link-scroll" onClick={() => scrollDown(geodyn)}>
                 {t('geodyn')}
               </li>

					     <li className="nav-link-scroll" onClick={() => scrollDown(seismo)}>
                 {t('seismo')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(gis)}>
							   {t('gis')}
							 </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(dev)}>
							   {t('dev')}
							 </li>
           </ul>
      </div>

      <div className="section subtitles" ref={geodyn}>
        <p>{t('geodyn')}</p>
      </div>

      <CardFlexboxRows data={t('tools.ptatin3d')} />
			<CardFlexboxRows data={t('tools.fantom2d')} />
      <CardFlexboxRows data={t('tools.fastscape')} />
			<CardFlexboxRows data={t('tools.gplates')} />


      <div className="section subtitles" ref={seismo}>
        <p>{t('seismo')}</p>
      </div>

      <CardFlexboxRows data={t('tools.maxi')} />
      <CardFlexboxRows data={t('tools.nlloc')} />
      <CardFlexboxRows data={t('tools.tomotv')} />
      <CardFlexboxRows data={t('tools.seisan')} />
      <CardFlexboxRows data={t('tools.antelope')} />

      <div className="section subtitles" ref={gis}>
        <p>{t('gis')}</p>
      </div>

      <CardFlexboxRows data={t('tools.qgis')} />
      <CardFlexboxRows data={t('tools.gmt')} />
      <CardFlexboxRows data={t('tools.geomapapp')} />
      <CardFlexboxRows data={t('tools.googleearth')} />
      <CardFlexboxRows data={t('tools.geoportail')} />

      <div className="section subtitles" ref={dev}>
        <p>{t('dev')}</p>
      </div>

      <CardFlexboxRows data={t('tools.bitbucket')} />
      <CardFlexboxRows data={t('tools.github')} />
      <CardFlexboxRows data={t('tools.vscode')} />

      <LargeFeature theme={Theme} />
    </>
  );
}


const Loader = () => (
  <div className="Home">
    <img src={logo} className="Home-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function tools() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}

