import React, {Suspense} from 'react';
/*import SmallFeatures from '../components/SmallFeatures';*/
import LargeFeature from '../components/LargeFeature';
import LargeFeatureContact from '../components/LargeFeatureContact';
import LargeFeatureFlexbox from '../components/LargeFeatureFlexbox';
/*import { productData, productDataTwo } from '../components/data/data';*/
import logo from '../assets/images/logo.svg';
import Wasatch_fault_Utah from '../assets/images/Wasatch_fault_Utah_GoogleEarth.jpg';
import MyFace from '../assets/images/accueil/tom2.jpg';
import LocImg from '../assets/images/accueil/Realfagsbygget.jpg';
import './index.css';
import { useTranslation } from 'react-i18next';
import {Main} from './IndexElements';

// Define what props.theme will look like
const HomePage = {
  theme: {
     bg_image: Wasatch_fault_Utah,
     title: 'Eastern rift flank (Basins and Ranges), Wasatch fault (Utah)',
     text: ''
  },
  figure: MyFace,
  text: {
     part1: 'homepage.part1'
  }
};

const contact = {
    img: LocImg,
    alt:  'Location',
    name: 'homepage.contact.title',
    desc: 'homepage.contact.text',
    price: '',
    button: 'visituib',
    url: 'https://www.uib.no/en/rg/gbs'
};

function Page () {
 
  const { t } = useTranslation();

  return (
     <Main>
       <div className="pagetitle">
         <h2>{t('hometitle')}</h2>
       </div>
       <LargeFeatureFlexbox title="Dr Thomas Theunissen" text={HomePage.text.part1} figure={HomePage.figure} />
       <LargeFeatureContact heading='Contact' data={contact} />
       <LargeFeature theme={HomePage.theme} />
  
  {/*      <SmallFeatures heading='Choose your favorite' data={productData} />
       <SmallFeatures heading='Sweet Treats for You' data={productDataTwo} /> */ }
     </Main>
  );
}

// loading component for suspense fallback
const Loader = () => (
  <div className="Home">
    <img src={logo} className="Home-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function Home() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}

