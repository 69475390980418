import React from 'react';
import {
  Main,
  ListPublicationsContainer,
  SmallFeatureWrapper,
  ListPublicationsHeading,
  SmallFeatureTitle,
  SmallFeatureCard,
  SmallFeatureImg,
  SmallFeatureJournal,
  SmallFeatureAuthors,
  SmallFeatureYear,
  ModalContent,
  PopupButton
} from './ListPublicationsElements';
import { withTranslation } from 'react-i18next';
import DownloadFileButton from '../DownloadFileButton';
//import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

class ListPublications extends React.Component {
 
  constructor(props) {
        super(props);
        this.state = { showPopup: this.props.showPopup , index: this.props.index };
        this.togglePopup = this.togglePopup.bind(this);
        console.log(this.props);
  }

  togglePopup(index) {  
    this.setState({  
      showPopup: !this.state.showPopup,
      index: index
    });  
  }

  render() {
    
    const { t } = this.props;

    return (
      <Main>

      <ListPublicationsContainer>
        <ListPublicationsHeading>{t(this.props.heading)}</ListPublicationsHeading>
        <SmallFeatureWrapper>
          {this.props.data.map((publication, index) => {
            return (
              <SmallFeatureCard key={index} onClick={() => this.togglePopup(index)} >
                <SmallFeatureImg src={publication.img} alt={publication.alt} />
               {/* <SmallFeatureInfo> */}
                  <SmallFeatureTitle>{publication.title}</SmallFeatureTitle>
                  <SmallFeatureAuthors>{publication.authors}</SmallFeatureAuthors>
                  <SmallFeatureJournal>{publication.journal}, {publication.url}</SmallFeatureJournal>
               {/* </SmallFeatureInfo> */}
                <SmallFeatureYear>{publication.year}</SmallFeatureYear>
                {/*<DownloadFileButton filename={publication.pdf} directory={publication.directory}/>
                <SmallFeatureButton>{t('download')}</SmallFeatureButton>*/}
              </SmallFeatureCard>
            );
          })}
        </SmallFeatureWrapper>

      </ListPublicationsContainer>

<Modal
  open={this.state.showPopup}
  onClose={() => this.togglePopup(this.state.index) }
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
>
	 <ModalContent>
            <h1> {this.props.data[this.state.index].title}   </h1>
            <h2> {this.props.data[this.state.index].journal}, <a target="_blank" rel="noreferrer" href={this.props.data[this.state.index].url}>{this.props.data[this.state.index].url}</a> </h2>
            <h2> {this.props.data[this.state.index].year}    </h2>
            <p> {this.props.data[this.state.index].summary}  </p>
            <PopupButton>
            <DownloadFileButton filename={this.props.data[this.state.index].pdf} directory={this.props.data[this.state.index].directory}/>
{/*              <FileDownloader filename={this.props.data[this.state.index].pdf} directory={this.props.data[this.state.index].directory} />*/}
            </PopupButton>
	    </ModalContent>

</Modal>

     </Main>


    )
  }
}

// Set default props
ListPublications.defaultProps = {
  showPopup: false,
  index: 0
};

export default withTranslation()(ListPublications);

