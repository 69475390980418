import axios from 'axios';
class Service {
  getRestClient() {
    if (!this.serviceInstance) {
      this.serviceInstance = axios.create({
        baseURL: 'https://theunissen.h.uib.no',   /*'https://theunissen.h.uib.no', 'http://localhost:3000/',*/
/*				baseURL: 'https://theunissen.h.uib.no', */
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json'
          },
      });
    }
    return this.serviceInstance;
  }
}

export default (new Service());

