import React, {Suspense} from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

/*import SmallFeatures from '../components/SmallFeatures';*/
import LargeFeature from '../components/LargeFeature';

//import CardFlexbox from '../components/CardFlexbox';
import CardFlexbox2 from '../components/CardFlexbox2';

/*import { productData, productDataTwo } from '../components/data/data';*/
import logo from '../assets/images/logo.svg';
import ProjectsJSON from '../content/projects.json';
import './index.css';
import OBSrecovery from '../assets/images/accueil/DSC05418.JPG';

// Define what props.theme will look like
const Theme = {
  bg_image: OBSrecovery,
  title: 'captionOBSrecovery',
  text: ''
};

function Page () {

  const { t } = useTranslation();

  const zechtecSection    = useRef(null);
  const deepinsaltSection = useRef(null);
  const palmarSection     = useRef(null);
  const deepriftSection   = useRef(null);
  const colorsSection     = useRef(null);
	const multiriftSection  = useRef(null);
	const sigma2Section     = useRef(null);
	const ratstaigerSection = useRef(null);
	const actsSection       = useRef(null);

  const scrollDown = (ref) => {
    window.scrollTo({
  	    top: ref.current.offsetTop,
  			    behavior: 'smooth',
  					  });
  };

  const selectionZECHTEC    = [...new Set(ProjectsJSON.filter(item => item.title === "zechtec"))];
  const selectionDEEPINSALT = [...new Set(ProjectsJSON.filter(item => item.title === "deepinsalt"))];
  const selectionPALMAR     = [...new Set(ProjectsJSON.filter(item => item.title === "palmar"))];
	const selectionDEEPRIFT   = [...new Set(ProjectsJSON.filter(item => item.title === "deeprift"))];
  const selectionCOLORS     = [...new Set(ProjectsJSON.filter(item => item.title === "colors"))];
	const selectionMULTIRIFT  = [...new Set(ProjectsJSON.filter(item => item.title === "multirift"))];
	const selectionSIGMA2     = [...new Set(ProjectsJSON.filter(item => item.title === "sigma2"))];
  const selectionRATSTAIGER = [...new Set(ProjectsJSON.filter(item => item.title === "ratstaiger"))];
  const selectionACTS       = [...new Set(ProjectsJSON.filter(item => item.title === "acts"))];

  return (
     <>

       <div className="section section1">
           <ul>
               <li className="nav-link-scroll" onClick={() => scrollDown(zechtecSection)}>
                 {t('zechtec')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(deepinsaltSection)}>
                 {t('deepinsalt')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(palmarSection)}>
                 {t('palmar')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(deepriftSection)}>
                 {t('deeprift')}
               </li>

					     <li className="nav-link-scroll" onClick={() => scrollDown(colorsSection)}>
                 {t('colors')}
               </li>

					     <li className="nav-link-scroll" onClick={() => scrollDown(multiriftSection)}>
                 {t('multirift')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(sigma2Section)}>
							   {t('sigma2')}
							 </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(ratstaigerSection)}>
                 {t('ratstaiger')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(actsSection)}>
                 {t('acts')}
               </li>

           </ul>
       </div>

       <div className="section section10" ref={zechtecSection}>
		     <CardFlexbox2 json={selectionZECHTEC} />
			 </div>

       <div className="section section9" ref={deepinsaltSection}>
		     <CardFlexbox2 json={selectionDEEPINSALT} />
			 </div>

       <div className="section section8" ref={palmarSection}>
		     <CardFlexbox2 json={selectionPALMAR} />
			 </div>

       <div className="section section7" ref={deepriftSection}>
		     <CardFlexbox2 json={selectionDEEPRIFT} />
			 </div>

       <div className="section section2" ref={colorsSection}>
		     <CardFlexbox2 json={selectionCOLORS} />
			 </div>

			 <div className="section section3" ref={multiriftSection}>
         <CardFlexbox2 json={selectionMULTIRIFT} />
			 </div>

       <div className="section section4" ref={sigma2Section}>
         <CardFlexbox2 json={selectionSIGMA2} />
       </div>

			 <div className="section section5" ref={ratstaigerSection}>
         <CardFlexbox2 json={selectionRATSTAIGER} />
			 </div>

       <div className="section section6" ref={actsSection}>
         <CardFlexbox2 json={selectionACTS} />
       </div>

       <LargeFeature theme={Theme} />
     </>
  );
}

// loading component for suspense fallback
const Loader = () => (
  <div className="Home">
    <img src={logo} className="Home-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function projects() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}

