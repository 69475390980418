import React, {Suspense} from 'react';
import LargeFeature from '../components/LargeFeature';
import ListPublications from '../components/ListPublications';
import { rapports,papers } from '../content/Publications';
import logo from '../assets/images/logo.svg';
import MyFace from '../assets/images/accueil/tom2.jpg';
import deathvalley201906 from '../assets/images/deathvalley201906.jpg';
import {Main} from './IndexElements';

// Define what props.theme will look like
const PublicationsPage = {
  theme: {
     bg_image: deathvalley201906,
     title: 'On top of a core complex dome (Death Valley, California)',
     text: ''
  },
  figure: MyFace,
  text: {
     part1: 'homepage.part1'
  }
};

function Page () {
  
  return (
     <Main>
       <ListPublications heading='papers' data={papers} />
       <ListPublications heading='rapports' data={rapports} />
       <LargeFeature theme={PublicationsPage.theme} />
     </Main>
  );
}

// loading component for suspense fallback
const Loader = () => (
  <div className="Publications">
    <img src={logo} className="Home-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function Publications() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}

