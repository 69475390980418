import styled, { css } from "styled-components";
import chroma from "chroma-js";

export const Cardcolumn = styled.div`
  width: 80%;
  margin: 20px auto 0 auto;
  max-width: 800px;
  -webkit-box-shadow: rgba(0,0,0,0.8) 0px 0 10px;
  -moz-box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;

  @media all and (max-width: 800px) {
    width: 100%;
  }

`;

export const SectionDescription = styled.div`
  min-height: 10vh;
  display: flex-column;
  justify-content: left;
  h2 {
    padding: 5px;
  }
  p {
    padding: 5px;
    font-size: 12px;
    font-style: italic;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 120px auto; //repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 5px;
  padding-bottom: 15px;
`;

export const ItemHead = styled.div`
  display: flex;
  justify-content: center;
  padding: .5rem;
  white-space: pre-line;
  align-items: center;
  text-align: center;

  background-color:#E0E0E0;

{/*  ${({ color = chroma.random() }) =>
    css`
      background-color: ${color};
      color: ${chroma.contrast(color, "black") >= 4 ? "black" : "white"};
      filter: grayscale(100%);
      opacity: 50%;
      font-size: 18px;
      font-weight: bold;
    `} */}
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  padding: .5rem;
  white-space: pre-line;
  align-items: center;
  text-align: center;

  background-color:rgba(224, 224, 224, 0.4);

{/*  ${({ color = chroma.random() }) =>
    css`
      background-color: ${color};
      color: ${chroma.contrast(color, "black") >= 4 ? "black" : "white"};
      filter: grayscale(100%);
      opacity: 50%;
      font-size: 18px;
    `} */}
`;



//
//.Cardflex {
//  margin: 0;
//  display: flex;
//  /*border-bottom: 5px solid #333;*/
//  background-color: #fff;
//  margin-bottom: 2rem;
//  border-radius: 20px;
//  -webkit-border-radius: 20px;
//  -moz-border-radius: 20px;
//}
//
//.Cardflex img {
//  display: block;
//  min-height:200px;
//  max-height: 400px;
//  max-width: 400px;
//  float: right;
//  margin: 10px;
//}
//.Cardflex img:hover {
//  cursor: pointer;
//}
//
///* optional: alternately flips module layout 
//.Cardflex:nth-child(odd) {
//  flex-direction: row-reverse;
//}
//*/
///*
//.Cardflex div:first-child {
//  flex: 1;
//  background-size: cover;
//  background-position: center;
//  margin: auto;
//  width: 50%;
//}
//*/
///*
//.Cardflex:first-of-type div:first-child {
//  background-image: url("../../assets/images/accueil/tom2.jpg");
//}
//.Cardflex:last-of-type div:first-child {
//  background-image: url("../../assets/images/accueil/Realfagsbygget.jpg");
//}
//*/
///*
//.Cardflex div:last-child {
//  margin: 2rem;
//  flex: 2;
//}
//*/
//.Cardflex h3 {
//  font-size: 1.5rem;
//  margin: 15px;
//  font-weight: 400;
//}
//.Cardflex p {
//  font-size: 1rem;
//  line-height: 1.4;
//  font-weight: 400;
//  white-space: pre-wrap;
//  margin: 15px;
//}
///*
//.Cardflex p:last-of-type {
//  margin-bottom: 0;
//}
//*/
//
//@media screen and (max-width: 600px) {
//  .Cardflex p {
//    clear: both;
//  }
//  .Cardflex img {
//    width: 100%;
//    margin: 0px auto 15px auto;
//    height:auto;
//    float:none;
//  }
//}
//@media all and (max-width: 800px) {
//  body { margin: 0; }
//  // .Cardflex div { flex: 1 !important; } optional: equalizes the visual "weight" of the photo and description
//  h2 {
//    margin: 1rem;
//  }
//  .Cardflex {
//    flex-direction: column;
//    border: 0;
//    margin: 0;
//    /*border-bottom: 2px solid #333;*/
//  }
//  /*
//  .Cardflex div:last-child {
//    margin: 1rem;
//  }
//  */
//  .Cardcolumn {
//    width: 100%;
//  }
//}
//
