import React from 'react';
import {
  LargeFeature3Container,
  LargeFeature3Wrapper,
  LargeFeature3Heading,
  LargeFeature3Title,
  LargeFeature3Card,
  LargeFeature3Img,
  LargeFeature3Info,
  LargeFeature3InfoImg,
  LargeFeature3Desc,
  LargeFeature3Price,
  LargeFeature3Button
} from './LargeFeature3Elements';
import { useTranslation } from 'react-i18next';

const LargeFeatureContact = ({ heading, data }) => {
  const { t } = useTranslation();
  return (
    <LargeFeature3Container>
      <LargeFeature3Heading>{t(heading, {keySeparator: '.'})}</LargeFeature3Heading>
      <LargeFeature3Wrapper>

            <LargeFeature3Card>
	      <LargeFeature3InfoImg>
                <LargeFeature3Img src={data.img} alt={data.alt} />
	      </LargeFeature3InfoImg>
              <LargeFeature3Info>
                <LargeFeature3Title>{t(data.name, {keySeparator: '.'})}</LargeFeature3Title>
                <LargeFeature3Desc>{t(data.desc, {keySeparator: '.'})}</LargeFeature3Desc>
                <LargeFeature3Price>{data.price}</LargeFeature3Price>
                <LargeFeature3Button onClick={e => {  e.preventDefault(); window.location.href = data.url;  }} >{t(data.button, {keySeparator: '.'})}</LargeFeature3Button>
              </LargeFeature3Info>
            </LargeFeature3Card>

      </LargeFeature3Wrapper>
    </LargeFeature3Container>
  );
};

export default LargeFeatureContact;

