import React, { Component } from 'react';
import { FileService }      from './file-service.jsx';
import { saveAs }           from 'file-saver';
import { DownloadButton }   from './DownloadFileButtonElements';
import { withTranslation }  from 'react-i18next';

 class DownloadFileButton extends Component {
    constructor() {
        super();
        this.fileService = new FileService();
        this.state={downloading:false};
    }

     extractFileName = (contentDispositionValue) => {
         var filename = "";
         if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
             var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
             var matches = filenameRegex.exec(contentDispositionValue);
             if (matches != null && matches[1]) {
                 filename = matches[1].replace(/['"]/g, '');
             }
         }
         return filename;
     }

    downloadFile = () => {
        this.setState({ downloading: true });
        let self = this;
        this.fileService.getFileFromServer("/"+this.props.directory+"/",this.props.filename).then((response) => {
            //console.log("Response", response);
            this.setState({ downloading: false});
            //extract file name from Content-Disposition header
            //var filename=this.extractFileName(response.headers['content-disposition']);
						var filename=this.props.filename;
            //console.log("File name",filename);
            //invoke 'Save As' dialog
            saveAs(response.data, filename);
        }).catch(function (error) {
            console.log(error);
            self.setState({ downloading: false });
            if (error.response) {
                console.log('Error', error.response.status);
            } else {
                console.log('Error', error.message);
            }
        });
    };

    render() {
        //console.log("state",this.state);
				const { t } = this.props;
        return (
            <div>
    {/*            <Button label="Download file" onClick={this.downloadFile} />  */}
		            <DownloadButton onClick={this.downloadFile}>{t('download')}</DownloadButton>
                <label>{this.state.downloading ? 'Downloading in progress' : ''}</label>
            </div>
        )
    };
}

export default withTranslation()(DownloadFileButton);
