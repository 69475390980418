import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import en_flag from "../../assets/images/drapeau_eng.png";
import fr_flag from "../../assets/images/drapeau_fr.png";
import no_flag from "../../assets/images/drapeau_no.png";
import Image from 'react-bootstrap/Image'

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

const LanguageSelect = () => {

  const languageMap = {
     en: { label: "English", flag: en_flag, dir: "ltr", active: false },
     fr: { label: "Français", flag: fr_flag, dir: "ltr", active: false },
     no: { label: "Norsk", flag:no_flag, dir: "ltr", active: true }
  };
  // flagMap and labelMap only for Safari to avoid TypeError: undefined is not an object (evaluating 'languageMap[selected].flag')
  const flagMap = {
     en: en_flag,
     fr: fr_flag,
     no: no_flag
  };

  const labelMap = {
     en: "English",
     fr: "Français",
     no: "Norsk"
  };

  const dirMap = {
     en: "ltr",
     fr: "ltr",
     no: "ltr"
  };

  const selected = localStorage.getItem("i18nextLng") || "no";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = dirMap[selected];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuAnchor, selected]);

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        {/*languageMap[selected].label*/}
        <Image src={flagMap[selected]} alt={labelMap[selected]} style={{height: 20}} fluid />
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div>
          <List>
            <ListSubheader>{t("select_language")}</ListSubheader>
            {Object.keys(languageMap)?.map(item => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                }}
              >
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;

