export const MenuItems = [
    {
        title: 'Recherche',
        alias: 'Recherche',
        url: '/recherche',
        cName: 'nav-links'
    },
    {
        title: 'Projects',
        alias: 'Projects',
        url: '/projects',
        cName: 'nav-links'
    },
    {
        title: 'Publications',
        alias: 'Publications',
        url: '/publications',
        cName: 'nav-links'
    },
    {
        title: 'Curriculum Vitae',
        alias: 'C.V.',
        url: '/curriculumvitae',
        cName: 'nav-links'
    },
    {
        title: 'Enseignement',
        alias: 'Enseignement',
        url: '/enseignement',
        cName: 'nav-links'
    },
    {
        title: 'Outils',
        alias: 'Outils',
        url: '/outils',
        cName: 'nav-links'
    }

]
