import React from 'react';
import { MenuItems } from "../Navbar/MenuItems";
import { Switch, Route } from 'react-router-dom';
import Content from './content';
import './Banner.css';
import { useTranslation } from 'react-i18next';

const Banner = () => {
  
  const { t } = useTranslation();

  return (
      <div className="BannerDiv">
         <Switch>
            <Route path='/' exact render={ (props) => ( <Content tag={t('Home')} />) }  />,
            {MenuItems.map((item, index) => {
                return (
                      <Route key={index} path={item.url} render={ (props) => ( <Content tag={t(item.title)} />) }  />
                )
            })}
         </Switch>
      </div>
  );
};

export default Banner;



