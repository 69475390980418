import styled from 'styled-components';

export const ModalContent = styled.div`
  position: absolute;
  background: #fff;
  width: 90%;
  max-height: 100vh;
  display: flex;
  flex-direction:column;
  overflow-y: scroll;
  overflow-x: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center; 
  h1 {
    color: #cd3e3f;
    text-align: center;
  }
  h2 {
    padding: 10px;
    text-align: center;
  }
  p {
    text-align: justify;
    padding: 0px 10px 30px 10px;
  }
`;

export const PopupButton = styled.div`
  height: 80px;
  width: 100%;
  align-items: center;
  text-align: center;
  display: inline-block;
  padding-bottom: 100px;
`;

export const Main = styled.div`
  overflow-y: scroll !important;
`;

export const ListPublicationsContainer = styled.div`
  /* width: 100vw; */
	overflow-y: scroll !important;
  margin: auto;
  min-height: 25vh;
  padding: 2rem calc((100vw - 5000px) / 2);
  background: #fff; //#150f0f;
  color: #000000; //#fff;
  max-width: 1366px;
`;

export const SmallFeatureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const SmallFeatureCard = styled.button`
  text-decoration: none;
  color: #000000;
  margin: 0rem 2rem 1rem 2rem;
  line-height: 2;
  width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  align-items: center;
//  align-content: space-between;
  justify-content: center;
  text-align: center;
  display:flex;
  flex-direction:column;

  @media all and (max-width: 800px) {
    width: 100%;
  }

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;

export const SmallFeatureImg = styled.img`
  height: 150px;
  min-width: 100px;
  max-width: 80%;
  box-shadow: 8px 8px #fdc500;
`;

export const ListPublicationsHeading = styled.h1`
  font-size: clamp(1rem, 1.25vw, 1.5rem);
  text-align: center;
  margin-bottom: 5rem;
`;

export const SmallFeatureTitle = styled.h2`
  font-weight: 800;
  font-size: 0.75rem;
`;

export const SmallFeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const SmallFeatureJournal = styled.p`
  margin-bottom: 1rem;
`;

export const SmallFeatureAuthors = styled.p`
  margin-bottom: 1rem;
  font-size: clamp(0.5rem, 0.75vw, 0.75rem);
  &.i {
      font-weight: bold;
  }
`;

export const SmallFeatureYear = styled.p`
  margin-top:auto;
  margin-bottom: 0px;
  font-size: 1rem;
  font-weight: 800;
`;

export const SmallFeatureButton = styled.button`
  margin-top:auto;
  margin-bottom:10px;
  font-size: 1rem;
  padding: 1rem 4rem;
  border: none;
  background: #e31837;
  color: #fff;
  transition: 0.2 ease-out;

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;

