import React from 'react';
import { LargeFeatureContainer/*, LargeFeatureButton*/ } from './LargeFeatureElements';
import LargeFeaturePic from '../../assets/images/Wasatch_fault_Utah_GoogleEarth.jpg';
import { useTranslation } from 'react-i18next';

function LargeFeature(props) {

  const { t } = useTranslation();

  return (
    <LargeFeatureContainer theme={props.theme}>
      <h1>{t(props.theme.title)}</h1>
      <p>{t(props.theme.text)}</p>
      {/*<LargeFeatureButton>Order Now</LargeFeatureButton>*/}
    </LargeFeatureContainer>
  );
};

// We are passing a default theme for Buttons that arent wrapped in the ThemeProvider
LargeFeature.defaultProps = {
  theme: {
    bg_image: LargeFeaturePic,
    title: 'Default title',
    text: 'Default text'
  }
}

export default LargeFeature;

