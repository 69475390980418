import React, { Component } from 'react';
import './LargeFeatureFlexbox.css';
import { withTranslation } from 'react-i18next';
import background from "../../assets/images/pexels-pixabay-87009.jpg";

class LargeFeatureFlexbox extends Component {

    constructor (props) {
        super(props)
        this.state = {
            clicked: false
        }
    }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {

        const { t } = this.props;

        return(

           <div className="column">
             <figure className="flex">
	       <div>
	       <img src={this.props.figure} alt={t(this.props.text, {keySeparator: '.'})} />
               {/*<div style = {{
			backgroundImage: `url(${this.props.figure})`
                     }}
		     ></div> */}
	       </div>
               <div>
                 <h3>{t(this.props.title, {keySeparator: '.'})}</h3>
                 <p>{t(this.props.text, {keySeparator: '.'})}</p>
               </div>
             </figure>
           </div>

        )
    }
} 

// Set default props
LargeFeatureFlexbox.defaultProps = {
  title: "Aloe polyphylla Schönland ex Pillans",
  figure: background,
  text: "Endangered native of the Maluti Mountains in Lesotho, Africa. Grows in rock crevices at high altitudes. Only alpine member of the genus Aloe. Threats to populations of spiral aloe include overgrazing, unsustainable harvesting by plant enthusiasts and people interested in its medicinal properties, and increasing rarity of its pollinator, the Malachite Sunbird."
};

export default withTranslation()(LargeFeatureFlexbox)
