import React, { Component } from 'react';
import { FaBars,FaMicroscope,FaTimes } from 'react-icons/fa';
import { MenuItems } from "./MenuItems";
import { UserData } from "../data";
/*import { Button } from "../Button"; */
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Navbar extends Component {

    constructor (props) {
        super(props)
        this.state = {
            clicked: false
        }
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
        this.setState({ clicked: false})
    }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        const { t } = this.props;

        return(
            <nav className="NavbarItems">
                <NavLink to='/' className='nav-links' onClick={() => this.closeMenu()} >
                    <h1 className="navbar-logo">{UserData.Name}<FaMicroscope className="fa-react" /></h1>
                </NavLink>
                <div className="menu-icon" onClick={this.handleClick}>
                    <FaBars className={this.state.clicked ? 'fa-bars-hidden' : 'fa-bars'}  />
                    <FaTimes className={this.state.clicked ? 'fa-times' : 'fa-times-hidden'} />
                </div>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                 <NavLink to={item.url} className={item.cName} onClick={() => this.closeMenu()} >
                                     {t(item.alias)}
                                 </NavLink>
                            </li>
                        )
                    })}
                </ul>
                { /*<Button>Sign Up</Button>*/ }
            </nav>
        )
    }
}

export default withTranslation()(Navbar)
