import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Banner from './components/Banner/Banner';
import { GlobalStyle } from './globalStyles';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './pages';
import Recherche from './pages/research';
import Projects from './pages/projects';
import Publications from './pages/publications';
import Outils from './pages/tools';
import CurriculumVitae from './pages/curriculumvitae';
import Enseignement from './pages/teaching';
import ScrollToTop from "react-scroll-to-top";
import ScrollToTopAuto from './components/ScrollToTopAuto';

//import ScrollMemory from 'react-router-scroll-memory';


function App() {

  return (
    <div className="App">
        <Router>
            <GlobalStyle />
            <Banner />
            <Navbar />
						<ScrollToTopAuto>
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/recherche' component={Recherche} />
								<Route path='/projects' component={Projects} />
                <Route path='/curriculumvitae' component={CurriculumVitae} />
                <Route path='/publications' component={Publications} />
                <Route path='/outils' component={Outils} />
                <Route path='/enseignement' component={Enseignement} />
            </Switch>
						</ScrollToTopAuto>
            <Footer />
        </Router>
				<ScrollToTop />
    </div>
  );
}

export default App;
