import React, {Suspense} from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

/*import SmallFeatures from '../components/SmallFeatures';*/
import LargeFeature from '../components/LargeFeature';

//import CardFlexbox from '../components/CardFlexbox';
import CardFlexbox2 from '../components/CardFlexbox2';

/*import { productData, productDataTwo } from '../components/data/data';*/
import logo from '../assets/images/logo.svg';
import ResearchJSON from '../content/research.json';
import './index.css';
import OBSrecovery from '../assets/images/accueil/DSC05418.JPG';
import {Main} from './IndexElements';

// Define what props.theme will look like
const Theme = {
  bg_image: OBSrecovery,
  title: 'captionOBSrecovery',
  text: ''
};

function Page () {

  const { t } = useTranslation();

  const overviewSection    = useRef(null);
	const geodynamicSection  = useRef(null);
	const earthquakesSection = useRef(null);
	const imagerySection     = useRef(null);
	const coupling_spSection = useRef(null);

  const scrollDown = (ref) => {
    window.scrollTo({
  	    top: ref.current.offsetTop,
  			    behavior: 'smooth',
  					  });
  };
  
  const selectionOVERVIEW    = [...new Set(ResearchJSON.filter(item => item.title === "overview"))];
	const selectionEARTHQUAKES = [...new Set(ResearchJSON.filter(item => item.title === "earthquakes"))];
	const selectionGEODYNAMIC  = [...new Set(ResearchJSON.filter(item => item.title === "geodynamic"))];
  const selectionIMAGERY     = [...new Set(ResearchJSON.filter(item => item.title === "imagery"))];
  const selectionCOUPLING_SP = [...new Set(ResearchJSON.filter(item => item.title === "coupling_sp"))];

  return (
     <Main>

       <div className="section section1">
           <ul>
					     <li className="nav-link-scroll" onClick={() => scrollDown(overviewSection)}>
                 {t('overview')}
               </li>

					     <li className="nav-link-scroll" onClick={() => scrollDown(geodynamicSection)}>
                 {t('geodynamic')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(coupling_spSection)}>
							   {t('coupling_sp')}
							 </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(earthquakesSection)}>
                 {t('earthquakes')}
               </li>

               <li className="nav-link-scroll" onClick={() => scrollDown(imagerySection)}>
                 {t('imagery')}
               </li>

           </ul>
       </div>

       <div className="section section2" ref={overviewSection}>
		     <CardFlexbox2 json={selectionOVERVIEW} />
			 </div>

			 <div className="section section3" ref={geodynamicSection}>
         <CardFlexbox2 json={selectionGEODYNAMIC} />
			 </div>

       <div className="section section4" ref={coupling_spSection}>
         <CardFlexbox2 json={selectionCOUPLING_SP} />
       </div>

			 <div className="section section5" ref={earthquakesSection}>
         <CardFlexbox2 json={selectionEARTHQUAKES} />
			 </div>

       <div className="section section6" ref={imagerySection}>
         <CardFlexbox2 json={selectionIMAGERY} />
       </div>

       <LargeFeature theme={Theme} />
     </Main>
  );
}

// loading component for suspense fallback
const Loader = () => (
  <div className="Home">
    <img src={logo} className="Home-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function research() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}

