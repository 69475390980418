import styled from 'styled-components';

export const LargeFeature3Container = styled.div`
  /* width: 100vw; */
  //min-height: 75vh;
  //padding: 2rem calc((100vw - 1500px) / 2);
  background: #fff; //#150f0f;
  color: #000000; //#fff;
  //margin-top: 2rem;
  white-space: pre-line;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;

  @media all and (max-width: 800px) {
    width: 100%;
  }
`;

export const LargeFeature3Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0;
  width:100%;
  
  @media all and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const LargeFeature3Card = styled.div`
  display: flex;
  margin: 0rem 0rem 0rem 0rem;
  line-height: 2;
  width: 100%;
/*  border: 1px solid #ddd;
  border-radius: 4px; */

  @media all and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const LargeFeature3InfoImg = styled.div`
  flex: 1;
  max-width: 40%;
  margin: auto;
  //overflow: auto;

  @media all and (max-width: 800px) {
    max-width: 80%;
  }
`;

export const LargeFeature3Img = styled.img`
  float:left;
  //margin: 0rem auto 0rem auto;
  //height: 400px;
  //min-width: 300px;
  //max-width:950px;
  display: block;
  max-height: 400px;
  max-width: 100%;
  height: auto;
  width: auto;
  box-shadow: 8px 8px #fdc500;
`;

export const LargeFeature3Info = styled.div`
  flex: 2;
  //display: flex;
  //flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 2rem;
  text-align: center;
`;

export const LargeFeature3Heading = styled.h2`
//  font-size: clamp(2rem, 2.5vw, 3rem);
//  text-align: center;
//  margin-bottom: 5rem;
  margin: 1rem;
`;

export const LargeFeature3Title = styled.h2`
  font-weight: 400;
  font-size: 1.5rem;
`;

export const LargeFeature3Desc = styled.p`
  margin-bottom: 1rem;
`;

export const LargeFeature3Price = styled.p`
  margin-bottom: 1rem;
  font-size: 2rem;
`;

export const LargeFeature3Button = styled.button`
  font-size: 1rem;
  padding: 1rem 4rem;
  border: none;
  background: #e31837;
  color: #fff;
  transition: 0.2 ease-out;

  &:hover {
    background: #ffc500;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;

