//import styled, { css } from "styled-components";
//import chroma from "chroma-js";
import styled from "styled-components";

export const Cardcolumn = styled.div`
  width: 80%;
  margin: 20px auto 0 auto;
  max-width: 800px;
  -webkit-box-shadow: rgba(0,0,0,0.8) 0px 0 10px;
  -moz-box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;

  @media all and (max-width: 800px) {
    width: 100%;
  }

`;

export const SectionDescription = styled.div`
  min-height: 10vh;
  display: flex-column;
  justify-content: left;
  h2 {
    padding: 5px;
  }
  p {
    padding: 5px;
    font-size: 12px;
    font-style: italic;
  }
  ul {
    padding-left: 30px;
  }
`;

export const List = styled.div`
  display: flex-column;
  justify-content: left;
  ul {
    padding-left: 30px;
    padding-bottom: 15px;
  }
  li {
    padding-bottom: 5px;
  }
`;

